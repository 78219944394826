<template>
  <div class="content">
    <div class="head">
      <div class="centerDiv">
        <div
          style="font-size: 0.4rem; font-weight: bold; margin-top: 0.1rem"
          class="flex aliCen"
        >
          <img
            src="@/images/logo2.png"
            alt=""
            style="width: 0.84rem; height: 0.8rem; margin-right: 0.16rem"
          />
          大鲸洗售后服务单
        </div>
        <div
          style="
            color: #fffeff;
            line-height: 1.5em;
            color: rgba(32, 32, 32, 0.4);
            font-size: 0.24rem;
          "
          class="maTo30"
        >
          遇到问题请勿担心，我们的售后专员会在提交售后48小时内与您联系，请您注意接听010开头的电话号码
        </div>
      </div>
    </div>
    <div class="postion">
      <div class="module" style="padding: 0.34rem 0">
        <div class="centerDiv2">
          <div class="title">查询订单</div>
          <van-form validate-first @submit="query" class="maTo30">
            <van-field
              v-model="telphone"
              placeholder="请输入手机号"
              style="background: #f7f9fb; border-radius: 0.16rem"
              :rules="[
                {
                  pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                  message: '请输入正确手机号',
                },
              ]"
            >
              <template #button>
                <van-button
                  size="small"
                  style="border-radius: 0.16rem"
                  color="#00CBCF"
                  >查询订单</van-button
                >
              </template>
            </van-field>
          </van-form>
        </div>
      </div>
      <div v-if="orderList.length > 0">
        <div
          v-for="(item, index) in orderList"
          :key="index"
          class="module"
          style="padding: 0.3rem 0"
        >
          <div class="centerDiv2">
            <div class="aftitle">订单号：{{ item.orderNo }}</div>
            <!-- <div class="canUseColor">{{ item.orderNo }}</div> -->
          </div>

          <div v-if="item.tsTicketVOList || item.asTicketVOList">
            <div
              class="pflex maTo30 canUseColor"
              v-show="!item.showTicket"
              @click="toShowTicket(index)"
            >
              展开<van-icon name="arrow-down" />
            </div>
            <div class="centerDiv" v-show="item.showTicket">
              <div
                v-for="(val, num) in item.tsTicketVOList"
                :key="num"
                class="maTo30"
                @click="showTicketDetail(val)"
              >
                <div class="flex bt aliCen">
                  <div>
                    工单号 <span class="mainColor">#{{ val.id }}</span>
                  </div>
                  <div class="canUseColor aliCen flex">
                    {{ val.createTime }}
                    <van-icon name="arrow" />
                  </div>
                </div>
              </div>
            </div>
            <div class="centerDiv" v-show="item.showTicket">
              <div
                v-for="(val, num) in item.asTicketVOList"
                :key="num"
                class="maTo30"
                @click="showTicketDetail(val)"
              >
                <div class="flex bt aliCen">
                  <div>
                    工单号 <span class="mainColor">#{{ val.id }}</span>
                  </div>
                  <div class="canUseColor aliCen flex">
                    {{ val.createTime }}
                    <van-icon name="arrow" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="pflex maTo30 canUseColor"
              v-show="item.showTicket"
              @click="toShowTicket(index)"
            >
              收起<van-icon name="arrow-up" />
            </div>
          </div>

          <div
            class="flex aliCen centerDiv2"
            style="justify-content: flex-end; margin-top: 0.3rem"
          >
            <!-- <van-button
            round
            size="small"
            plain
            type="info"
            @click="complaint(item)"
            >发起投诉</van-button
          > -->
            <van-button
              size="small"
              plain
              color="#00CBCF"
              style="margin-left: 0.15rem; border-radius: 0.14rem"
              @click="service(item)"
              >提交售后</van-button
            >
          </div>
        </div>
      </div>

      <div class="centerDiv" style="margin-top: 0.38rem">
        <div class="title">温馨提示</div>
        <div class="commonText maTo20">
          1.请注意衣物不要穿着并不要丢弃洗涤标签
        </div>
        <div class="commonText">
          2.提交服务单后请您保持手机畅通，售后工作人员会在48小时内联系您处理
        </div>
        <div class="commonText">3.请在提供售后照片时，同时提供水洗码照片</div>
      </div>
    </div>

    <van-popup
      v-model="showDetail"
      round
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div class="centerDiv">
        <van-cell-group>
          <van-cell
            :title="'工单ID' + ticketDetail.id"
            :value="ticketDetail.createTime"
            :label="ticketDetail.title"
          />
        </van-cell-group>
        <div v-html="ticketDetail.remark" class="maTo30 htmlDiv"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderList: [],
      telphone: "",
      userInfo: {},
      loading: false,
      showDetail: false,
      ticketDetail: {},
    };
  },
  created() {
    const that = this;
    if (that.$route.query.tel) {
      that.telphone = that.$route.query.tel;
      that.query();
    }
  },
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = false;
    next();
  },
  // mounted() {
  //   if (window.history && window.history.pushState) {
  //     history.pushState(null, null, document.URL);
  //     window.addEventListener("popstate", this.goBack, false);
  //   }
  // },
  methods: {
    showTicketDetail(ticket) {
      this.ticketDetail = ticket || {};
      this.showDetail = true;
    },
    complaint(item) {
      this.$router.push({
        path: "/afterSale/complaint",
        query: {
          orderId: item.id,
          orderNo: item.orderNo,
          brand: item.brand,
          tel: this.userTel,
        },
      });
    },
    service(item) {
      this.$router.push({
        path: "/afterSale/service",
        query: {
          orderId: item.id,
          orderNo: item.orderNo,
          brand: item.brand,
          tel: this.userTel,
          source: item.source,
        },
      });
    },
    toShowTicket(index) {
      this.orderList[index].showTicket = !this.orderList[index].showTicket;
    },
    goBack() {
      history.pushState(null, null, document.URL);
    },
    // 根据手机号查询新系统订单
    queryWaterFall() {
      const that = this;
      that.http
        .get(`https://ticket.dajingxiapp.com/api/orders/phone/${that.telphone}`)
        .then((res) => {
          that.loading = false;
          if (res.data.stateVO.code == 200) {
            let orderList = res.data.ticketOrderVOList;
            for (let i of orderList) {
              i.showTicket = false;
              i.source = "waterfall";
            }
            that.orderList = that.orderList.concat(orderList);
            console.log(that.orderList);
            if (that.orderList.length == 0) {
              that.$toast("没有可以申请售后的订单哦");
            } else {
              that.userTel = that.telphone;
            }
          } else {
            that.$toast(res.data.stateVO.msg);
          }
        });
    },
    // 根据手机号查询订单
    query() {
      const that = this;
      that.loading = true;
      that.http
        .get(
          `https://weixin.dajingxiapp.com/apiv2/order/listByTicket.json?telphone=${that.telphone}`
        )
        .then((res) => {
          // that.loading = false;
          if (res.data.stateVO.code == 200) {
            let orderList = res.data.ticketOrderVOList;
            for (let i of orderList) {
              i.showTicket = false;
              i.source = "dajingxi";
            }
            that.orderList = orderList;
            that.queryWaterFall();
            // that.userTel = that.telphone;
          } else {
            // that.$toast(res.data.stateVO.msg);
            that.orderList = [];
            that.queryWaterFall();
          }
        })
        .catch((err) => {
          that.loading = false;
          window.console.log(err);
        });
    },
  },
};
</script>

<style  scoped>
body {
  background-color: #f3f4f5;
}

.content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  letter-spacing: 0.01rem;
}

.head {
  /* background-color: #85d4db; */
  background-image: linear-gradient(to bottom, #a1e4ea 0, #f8f9fb 100%);
  flex-direction: column;
  padding: 0.8rem 0 1.8rem 0;
}

::v-deep.htmlDiv img {
  width: 100%;
  margin-top: 0.2rem;
}

.centerDiv {
  width: 88%;
}

.module {
  width: 88%;
}

.postion {
  position: relative;
  top: -1.2rem;
}

::v-deep .van-field__body {
  width: 96%;
  margin: 0 auto;
}

::v-deep .van-field__error-message {
  width: 96%;
  margin: 0 auto;
}

.centerDiv2 {
  width: 93%;
  margin: 0 auto;
}
</style>
