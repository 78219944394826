import router from "@/router.js"
// import {
//     Toast
// } from 'vant';
router.beforeEach((to, from, next) => {
    let flag = localStorage.getItem('userInfo')
    if (to.meta.requireAuth == true) {
        if (!flag || flag == "undefined" || flag == undefined) {
            next({
                    path: '/login'
                })
                // return next();
        } else {
            flag = JSON.parse(flag)
            if (flag.telphone) {
                return next();
            } else {
                next({
                        path: '/login'
                    })
                    // return next();
            }
        }
    } else {
        return next();
    }
})

export default router;