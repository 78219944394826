function envConfig() {
    let env = 'save' // save  dev
    let envConfigObj = {}
    switch (env) {
        case 'dev':
            envConfigObj = {
                baseUrl: "/api",
                env: env,
                supplementGoodId: 1865,
                supplementGoodImg: "https://file.haishenxiyi.com/goods/Fk7trGVD4y9QuymjZUCFbVMQC14i"
            }
            break;
        case 'save':
            envConfigObj = {
                baseUrl: "/api",
                env: env,
                supplementGoodId: 1930,
                supplementGoodImg: "https://file.haishenxiyi.com/goods/Fk7trGVD4y9QuymjZUCFbVMQC14i"
            }
            break;
    }
    return envConfigObj
}

export default envConfig;