import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/views/index/index.vue'
import Result from '@/views/index/result.vue'
import AfterSale from '@/views/afterSale/afterSale.vue'
import Service from '@/views/afterSale/service.vue'
import AfterResult from '@/views/afterSale/result.vue'
import Complaint from '@/views/afterSale/complaint.vue'
Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [{
            path: "/",
            name: "index",
            component: Index,
            meta: {
                cName: "大鲸洗",
                noNav: true,
                title: '大鲸洗',
                nav: '#ED9027'
            }
        },
        {
            path: "/result",
            name: "result",
            component: Result,
            meta: {
                cName: "大鲸洗",
                noNav: true,
                title: '大鲸洗',
                nav: '#ED9027'
            }
        },
        {
            path: "/aftersale",
            name: "afterSale",
            component: AfterSale,
            meta: {
                cName: "售后单",
                noNav: true,
                title: '售后单',
                nav: '#ED9027'
            }
        },
        {
            path: "/afterSale/service",
            name: "service",
            component: Service,
            meta: {
                cName: "售后单",
                noNav: true,
                title: '售后单',
                nav: '#ED9027'
            }
        },
        {
            path: "/afterSale/result",
            name: "afterResult",
            component: AfterResult,
            meta: {
                cName: "售后单",
                noNav: true,
                title: '售后单',
                nav: '#ED9027'
            }
        },
        {
            path: "/afterSale/complaint",
            name: "complaint",
            component: Complaint,
            meta: {
                cName: "售后单",
                noNav: true,
                title: '售后单',
                nav: '#ED9027'
            }
        },
    ]
})