<template>
  <div class="content">
    <div class="couponHead cflex">
      <div class="pflex">
        <img
          :src="couponInfo.logoUrl"
          alt=""
          style="width: 60%"
          v-if="couponInfo.logoUrl"
        />
        <img src="@/images/logo.png" alt="" style="width: 2.4rem" v-else />
      </div>
      <img
        src="@/images/bottomLogo.png"
        alt=""
        style="width: 65%"
        class="maTo30"
      />
      <div
        class="title centerDiv pflex"
        style="font-size: 0.4rem; margin-top: 0.5rem; line-height: 1.5em"
      >
        {{ couponInfo.name || "大鲸洗团购券" }}
      </div>
      <div class="maTo20 title">免费上门取送</div>
    </div>
    <div class="moudle centerDiv">
      <div class="centerDiv cflex">
        <van-field
          border
          v-model="telphone"
          name="手机号"
          label="手机号"
          placeholder="请输入手机号"
        />
        <div class="maTo20 flex aliCen bt" style="width: 100%">
          <div>购买数量</div>
          <van-stepper v-model="buynum" theme="round" integer />
        </div>
        <div class="maTo30" style="width: 100%">
          <van-button
            round
            type="primary"
            block
            color="#e86ca2"
            @click="toBuy"
            :loading="isLoading"
          >
            <span v-if="couponInfo.sellAmount"
              >¥ {{ couponInfo.sellAmount * buynum }}
            </span>
            <span>优惠购买</span>
          </van-button>
        </div>
      </div>
    </div>
    <div class="moudle centerDiv maTo30">
      <div class="centerDiv cflex">
        <div class="title">活动规则</div>
        <div
          class="maTo20"
          style="line-height: 1.5em"
          v-if="!couponInfo.sellRemark"
        >
          <p>
            1. 限量10000份，售罄为止；本套餐不可拆分使用，须一次性体验完毕；
          </p>
          <p>
            2.可洗衣物：羽绒服/呢大衣/风衣/棉衣、西服上衣/夹克、连衣裙/半身裙、衬衣/T恤、西裤/休闲裤、小白鞋/网面运动鞋/帆布鞋。
            （不论长短，举例：2件羽绒服，1件羊毛大衣，1双帆布鞋）（其他未列出详见下单说明）
          </p>
          <p>
            3.购买后红包会直接发放到大鲸洗账号（进入大鲸洗微信公众号，点击我的--团购预约-绑定下单手机号码--查看红包）
          </p>
          <p>
            4.购买红包起一年内有效（其中1月15日至1月31日为春节工厂休息期间，无法使用，敬请谅解）
          </p>
          <p>
            5.可用地区：北京、天津、河北、上海、江苏、浙江、安徽、广东、深圳、湖北、四川、重庆、陕西、河南。（不含湖南）
          </p>
          <p>衣物请简单包装、请勿使用贵重包装物，包装工厂默认丢弃</p>
          <div class="maTo10">
            <div class="pricetabletitle">
              <span class="titletext">衣物洗涤时长</span>
            </div>
            <div class="tablebody">
              <div
                class="pricetablecell"
                style="color: white; background: rgb(99, 188, 192)"
                v-for="(item, index) in thlist"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <div class="tdtablebody">
              <div
                class="pricetablecell"
                style="font-size: 0.22rem"
                v-for="(item, index) in td1"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <div class="tdtablebody">
              <div
                class="pricetablecell"
                style="font-size: 0.22rem"
                v-for="(item, index) in td2"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <div class="tdtablebody">
              <div
                class="pricetablecell"
                style="font-size: 0.22rem"
                v-for="(item, index) in td3"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="pricetabletitle">
            <span class="titletext centerDiv" style="font-size: 0.15rem"
              >备注：洗涤周期按订单中涉及最高时长为准，暂无付费加急业务。</span
            >
          </div>
          <p class="maTo10">
            售后保障：遇到没洗干净/漏发等问题请勿担心，快递显示已签收次日起48小时内请联系大鲸洗在线客服，我们将第一时间为您解决
          </p>
          <p>
            a.
            如实际送到工厂的衣物判定为不洗的，只能退还现金抵用红包，不支持提现；如实际送洗衣物数量少于4件的，不予退款/退红包；
          </p>
          <p>b. 未尽事宜，请联系公众号大鲸洗在线客服。</p>
        </div>
        <div
          v-else
          class="maTo20"
          v-html="couponInfo.sellRemark"
          style="line-height: 1.5em"
        ></div>
      </div>
    </div>
    <div class="moudle centerDiv maTo30">
      <div class="centerDiv flex bt aliCen" style="width: 59%">
        <img src="@/images/QRcode.png" alt="" style="width: 48%" />
        <img src="@/images/zhiwen.png" alt="" style="width: 48%" />
      </div>
      <div class="maTo30 pflex pink">长按进入大鲸洗微信公众号</div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      telphone: "",
      buynum: 1,
      sellId: "",
      thlist: ["衣物类别", "常规洗涤周期", "旺季洗衣周期"],
      td1: ["普通衣物订单", "3-5天", "5-7天左右"],
      td2: ["含鞋子订单", "5-7天", "7-10天左右"],
      td3: ["有难处理污渍/特殊问题的订单", "延时1-2天", "延时1-2天"],
      // baseUrl: "https://weixin.dajingxiapp.com/apiv2/",
      baseUrl: "rain_weixin/",
      isLoading: false,
      couponInfo: {},
      code: "",
      openId: "",
    };
  },
  created() {
    const that = this;
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.inWechat = true;
      let reg = new RegExp("(^|&)" + "code" + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r) {
        that.code = r[2];
        console.log("code".that.code);
      }
      if (!r) {
        window.location =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx25ae2da748515faf&redirect_uri=" +
          encodeURIComponent(window.location.href) +
          "&response_type=code&scope=snsapi_base&state=DAJINGXI";
      }
    }
  },
  mounted() {
    const that = this;
    that.sellId = that.$route.query.sellId || "";
    that.wxJsApi();
    if (that.sellId) {
      that.getCoupon();
      if (that.code) {
        that.wxJsApi();
      }
    }
  },
  methods: {
    wxJsApi() {
      const that = this;
      // if (that.code) {
        that.isLoading = true;
        that.http
          .post(`${that.baseUrl}recharge/jsapiConfig`, {
            code: "031LR5000u5G8R1jVG000YLfKL0LR502",
          })
          .then((res) => {
            that.isLoading = false;
            that.openId = res.data.openId || "";
            if (res.data.appId) {
              wx.config({
                debug: false,
                appId: res.data.appId,
                timestamp: res.data.timestamp,
                nonceStr: res.data.nonceStr,
                signature: res.data.signature,
                jsApiList: [
                  "chooseWXPay",
                  "checkJsApi",
                  "onMenuShareTimeline",
                  "onMenuShareAppMessage",
                ],
              });
              if (that.couponInfo.shareVO) {
                let shareWxLink =
                  "https://weixin.dajingxiapp.com/#/?sellId=" + that.sellId;
                wx.ready(function () {
                  wx.onMenuShareTimeline({
                    title: that.couponInfo.shareVO.title, // 分享标题
                    link: shareWxLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: that.couponInfo.shareVO.iconUrl, // 分享图标
                  });
                  wx.onMenuShareAppMessage({
                    title: that.couponInfo.shareVO.title, // 分享标题
                    desc: that.couponInfo.shareVO.content, // 分享描述
                    link: shareWxLink,
                    imgUrl: that.couponInfo.shareVO.iconUrl, // 分享图标
                    type: "", // 分享类型,music、video或link，不填默认为link
                    dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
                  });
                });
              }
            } else {
              that.$toast("请重试");
            }
          })
          .catch(() => {
            that.isLoading = false;
          });
      // }
    },
    getCoupon() {
      const that = this;
      that.isLoading = true;
      that.http
        .get(`${that.baseUrl}coupon/detail.json`, {
          sellId: that.sellId,
        })
        .then((res) => {
          that.isLoading = false;
          if (res.data.id) {
            that.couponInfo = res.data;
          }
        })
        .catch(() => {
          that.isLoading = false;
        });
    },
    toBuy() {
      const that = this;
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(that.telphone)) {
        that.$toast("手机号错误");
      } else {
        that.$dialog
          .alert({
            title: "温馨提示",
            message: `您的购买手机号为<span class='pink'>${that.telphone}</span>?`,
            theme: "round-button",
            showCancelButton: true,
            confirmButtonColor: "#76c4cf",
            cancelButtonColor: "#dfe1e5",
          })
          .then(() => {
            // 支付
            if (that.inWechat) {
              // 微信内支付
              that.wechatPay();
            } else {
              // 微信外支付
              that.browserPay();
            }
          })
          .catch(() => {});
      }
    },
    wechatPay() {
      const that = this;
      that.http
        .get(`${that.baseUrl}recharge/prePayCoupon.json`, {
          sellId: that.sellId,
          quantity: that.buynum,
          telphone: that.telphone,
          type: "coupon", //订单类型
          payMethod: "JSAPI",
          openId: that.openId,
        })
        .then((res) => {
          if (res.data.prePayVO) {
            wx.chooseWXPay({
              appId: res.data.prePayVO.appId,
              timestamp: res.data.prePayVO.timeStamp,
              nonceStr: res.data.prePayVO.nonceStr,
              package: res.data.prePayVO.packages,
              signType: res.data.prePayVO.signType,
              paySign: res.data.prePayVO.paySign,
              success() {
                that.$toast("支付完成");
              },
              fail() {
                that.$toast.fail("支付失败");
              },
            });
          }
        });
    },
    browserPay() {
      const that = this;
      that.http
        .get(`${that.baseUrl}recharge/prePayCoupon.json`, {
          sellId: that.sellId,
          quantity: that.buynum, //购买数量
          telphone: that.telphone, //手机号
          type: "coupon", //订单类型
          payMethod: "MWEB",
        })
        .then((res) => {
          window.location = res.data.prePayVO.mwebUrl;
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    document.body.style.backgroundColor = "rgb(133, 212, 219)";
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.body.style.backgroundColor = "#ffffff";
    next();
  },
};
</script>

<style scoped>
.couponHead {
  color: #fff;
  padding: 0.5rem 0 0.8rem 0;
  background-color: #59c3ce;
  background-image: url(https://static.dajingxiapp.com/miniApp/bgi.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.moudle {
  border-radius: 0.18rem;
  padding: 0.2rem 0;
  background-color: #fff;
  position: relative;
  top: -0.5rem;
  width: 87%;
}

.pricetabletitle {
  width: 100%;
  background-color: rgb(64, 181, 188);
  display: flex;
  padding: 0.1rem 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.tablebody {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tablebody > div {
  text-align: center;
  width: 33.3%;
  background-color: rgb(26, 155, 252);
  color: white;
}
.tdtablebody {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tdtablebody > div {
  text-align: center;
  width: 33.3%;
}

.pricetablecell {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0;
  line-height: 1.4em;
  border: rgb(74, 186, 193) solid 1px;
}
</style>