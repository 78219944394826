<template>
  <div class="content">
    <!-- 头部 -->
    <div class="head">
      <div class="centerDiv">
        <div
          style="font-size: 0.4rem; font-weight: bold; margin-top: 0.1rem"
          class="flex aliCen"
        >
          <img
            src="@/images/logo2.png"
            alt=""
            style="width: 0.84rem; height: 0.8rem; margin-right: 0.16rem"
          />
          大鲸洗售后服务单
        </div>
        <div
          style="
            color: #fffeff;
            line-height: 1.5em;
            color: rgba(32, 32, 32, 0.4);
            font-size: 0.24rem;
          "
          class="maTo30"
        >
          遇到问题请勿担心，我们的售后专员会在提交售后48小时内与您联系，请您注意接听010开头的电话号码
        </div>
      </div>
    </div>

    <div class="cflex position">
      <img
        src="https://static.dajingxiapp.com/miniApp/afsuccess.png"
        alt=""
        class="centerDiv"
        style="width: 60%"
      />
      <div style="font-size: 0.33rem">提交成功 !</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userTel: "", // 用户手机号
    };
  },
  created() {
    this.userTel = this.$route.query.tel;
  },
  methods: {
    back: function () {
      this.$router.push({
        path: "/",
        query: { tel: this.userTel },
      });
    },
  },
};
</script>

<style scoped>
.head {
  /* background-color: #85d4db; */
  background-image: linear-gradient(to bottom, #a1e4ea 0, #f8f9fb 100%);
  flex-direction: column;
  padding: 0.8rem 0 1.8rem 0;
}

.position {
  position: relative;
  top: -1.2rem;
  color: #202020;
}

.centerDiv2 {
    width: 93%;
    margin: 0 auto;
}
</style>