<template>
  <div class="content">
    <div class="head">
      <div class="centerDiv">
        <div
          style="font-size: 0.4rem; font-weight: bold; margin-top: 0.1rem"
          class="flex aliCen"
        >
          <img
            src="@/images/logo2.png"
            alt=""
            style="width: 0.84rem; height: 0.8rem; margin-right: 0.16rem"
          />
          大鲸洗售后服务单
        </div>
        <!-- <div
          style="
            color: #fffeff;
            line-height: 1.5em;
            color: rgba(32, 32, 32, 0.4);
            font-size: 0.24rem;
          "
          class="maTo30"
        >
          遇到问题请勿担心，我们的售后专员会在提交售后48小时内与您联系，请您注意接听010开头的电话号码
        </div> -->
      </div>
    </div>
    <div class="postion">
      <!-- 水洗码展示 -->
      <div class="module pflex" style="flex-direction: column">
        <div class="centerDiv2">
          <div class="orderNo">订单编号：{{ orderNo }}</div>
          <div class="maTo30">上传售后凭证</div>
          <div class="orangeMoudle maTo20">
            <div class="centerDiv2" style="color: #ff442e">
              温馨提示：请您在上传衣物照片的同时上传完整水洗码图片（如下图）。
            </div>
            <img
              src="https://static.dajingxiapp.com/miniApp/asWashCode.png"
              alt=""
              class="centerDiv2"
              style="margin-top: 0.2rem"
            />
          </div>
          <div class="maTo20">
            <div class="upload1 flex aliCen">
              <van-uploader
                :after-read="afterRead"
                v-model="picList"
                :before-delete="deleteimg"
                :max-count="10"
              />
              <div>
                <div>上传照片</div>
                <div
                  style="font-size: 0.24rem; color: rgba(32, 32, 32, 0.4)"
                  class="maTo20"
                >
                  请按照提示上传售后凭证!
                </div>
              </div>
            </div>
            <div class="upload2" v-if="picList.length > 0">
              <van-uploader
                :after-read="afterRead"
                v-model="picList"
                :before-delete="deleteimg"
                :max-count="10"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 遇到的问题 -->
      <div class="module">
        <div class="centerDiv2">
          <div>您遇到的问题</div>
          <div
            class="flex centerDiv2"
            style="flex-wrap: wrap; width: 95%; margin-top: 0.3rem"
          >
            <!-- 问题列表循环 -->
            <div
              class="pflex"
              v-for="(item, index) in labelList"
              :key="item.id"
              style="
                min-width: 50%;
                justify-content: flex-start;
                padding: 0.1rem 0;
              "
              @click="chooseQuestion(index)"
            >
              <van-checkbox
                v-model="item.choose"
                checked-color="#F4B48C"
              ></van-checkbox>
              <div class="commonText" style="margin-left: 0.2rem">
                {{ item.lableName }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 输入框 -->
      <div class="module cflex">
        <div class="centerDiv2">
          <div>留言</div>
          <van-field
            class="maTo20"
            v-model="remark"
            rows="2"
            autosize
            type="textarea"
            style="
              background: #f7f9fb;
              border-radius: 0.18rem;
              padding: 0.12rem 0.15rem;
            "
            maxlength="150"
            placeholder="请输入留言"
            show-word-limit
          />
        </div>
      </div>

      <!-- 图片上传 -->
      <!-- <div class="module">
        <div class="centerDiv2">
          <div>上传您的图片</div>
          <div class="maTo20">
            <van-uploader
              :after-read="afterRead"
              v-model="picList"
              :before-delete="deleteimg"
              :max-count="10"
            />
          </div>
        </div>
      </div> -->

      <!-- 解决方案 -->
      <div class="module">
        <div class="centerDiv2">
          <div>您期望的解决方案</div>
          <div class="maTo30 flex aliCen bt canUseColor" @click="show">
            <div>{{ userChooseProgramme }}</div>
            <van-icon name="arrow" />
          </div>
          <van-action-sheet
            v-model="showProgramme"
            :actions="programme"
            @select="chooseProgramme"
          />
        </div>
      </div>

      <!-- 占位 -->
      <div style="width: 100%; height: 1.5rem"></div>
    </div>

    <!-- 底部按钮 -->
    <div class="pflex" style="position: fixed; bottom: 0.4rem; width: 100%">
      <div class="centerDiv">
        <van-button
          type="primary"
          block
          color="#00CBCF"
          @click="submission"
          style="border-radius: 0.22rem"
          >提交售后</van-button
        >
      </div>
    </div>

    <!-- 占位 -->
    <div style="width: 100%; height: 0.4rem"></div>
  </div>
</template>

<script>
// import djxChoose from "../components/djxChoose";
import { Uploader, Loading, Toast, Checkbox, ActionSheet } from "vant";
import "vant/lib/uploader/style";
import "vant/lib/loading/style";
import "vant/lib/toast/style";
import "vant/lib/checkbox/style";
import "vant/lib/action-sheet/style";
// import uploadApi from '../tool/uploadFile'
export default {
  name: "App",
  data() {
    return {
      orderId: "", // orderId
      orderNo: "", // 订单编号
      labelList: [], // 标签列表
      programme: [
        { name: "返厂重新处理" },
        { name: "退我相关衣物洗涤费，我自行处理" },
        { name: "找到并补发衣物/附件" },
        { name: "找到并补发衣物/附件" },
        { name: "请联系我沟通解决方案" },
      ],
      userChooseProgramme: "请选择",
      showProgramme: false,
      remark: "", // 填写内容
      picList: [],
      loading: true, // 是否加载状态
      picUrlList: [],
      memberId: "",
      token: "",
      openId: "",
      userTel: "",
      isUpload: false, // 是否正在上传图片
      brand: "meituan",
      requestUrl: "https://weixin.dajingxiapp.com/apiv2/ticket/add.json",
      source: "dajingxi",
    };
  },
  created() {
    const that = this;
    that.orderId = that.$route.query.orderId;
    that.orderNo = that.$route.query.orderNo;
    that.userTel = that.$route.query.tel;
    that.brand = that.$route.query.brand || "meituan";
    that.source = that.$route.query.source || "dajingxi";
    if (that.$route.query.source == "dajingxi") {
      that.requestUrl = "https://weixin.dajingxiapp.com/apiv2/ticket/add.json";
    } else {
      that.requestUrl = `https://${that.brand}.dajingxiapp.com/api/ticket/add`;
    }
    if (that.$route.query.token) {
      that.token = that.$route.query.token;
    }
    if (that.$route.query.memberId) {
      that.memberId = that.$route.query.memberId;
    }
    if (that.$route.query.openId) {
      that.openId = that.$route.query.openId;
    }
    that.loading = true;
    this.http
      .get(`https://weixin.dajingxiapp.com/apiv2/ticket/labelList.json`, {
        ticketType: 2,
      })
      .then((res) => {
        that.loading = false;
        that.labelList = res.data;
        for (const i of that.labelList) {
          i.choose = false;
        }
      })
      .catch(() => {
        that.loading = false;
      });
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == "/") {
      to.meta.keepAlive = true;
    } else {
      to.meta.keepAlive = false;
    }
    next();
  },
  methods: {
    // 照片删除回调
    deleteimg(e, name) {
      window.console.log(e);
      this.picUrlList.splice(name.index, 1);
      return true;
    },
    //   选择遇到的问题
    chooseQuestion(index) {
      this.labelList[index].choose = !this.labelList[index].choose;
      this.$forceUpdate();
    },
    afterRead(file) {
      const that = this;
      function random() {
        const min = 100000;
        const max = 999999;
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      function extractPath(inputString) {
        const startIndex = inputString.indexOf("${filename}");
        if (startIndex !== -1) {
          return inputString.substring(0, startIndex);
        } else {
          return inputString;
        }
      }
      that.isUpload = true;
      file.status = "uploading";
      file.message = "上传中...";
      const fileObj = file.file; // 当前的图片
      that.http
        .get(
          // `https://as.dajingxiapp.com/apiv2/upload/token/ticket?token=56890a69-8658-4217-a1c6-6a27dba9f2f1`
          `https://ticket.dajingxiapp.com/api/ticket/s3policy`
        )
        .then((res) => {
          if (res.data.fields) {
            const reader = new FileReader();
            const policy = res.data.fields;
            reader.readAsArrayBuffer(fileObj);
            reader.onload = () => {
              const arrayBuffer = reader.result;
              const blob = new Blob([arrayBuffer], { type: fileObj.type });
              let fd = new FormData();

              for (let key in policy) {
                fd.append(key, policy[key]);
              }
              let newDate = new Date().getTime();
              let filename = newDate + random();

              fd.append("Content-Type", fileObj.type);
              fd.append("file", blob, filename);
              // fd.append("X-Amz-Credential", policy["X-Amz-Credential"]);
              // fd.append("X-Amz-Algorithm", policy["X-Amz-Algorithm"]);
              // fd.append("X-Amz-Date", policy["X-Amz-Date"]);
              // fd.append("Policy", policy.Policy);
              // fd.append("X-Amz-Signature", policy["X-Amz-Signature"]);
              // fd.append("X-Amz-Security-Token", policy["X-Amz-Security-Token"]);
              // fd.append("bucket", policy["bucket"]);
              // fd.append("key", policy["key"]);
              // fd.append("file", blob);

              const xhr = new XMLHttpRequest();
              xhr.open("POST", res.data.url, true);
              xhr.onreadystatechange = () => {
                that.isUpload = false;
                if (xhr.readyState === 4) {
                  if (xhr.status === 204) {
                    const dataUrl = `https://waterfall.dajingxiapp.com/${
                      extractPath(policy.key) + filename
                    }`;
                    that.picUrlList.push(dataUrl);
                    console.log(that.picUrlList);
                    file.status = "done";
                    file.message = "上传完成";
                  } else {
                    file.status = "failed";
                    file.message = "上传失败";
                  }
                }
              };
              xhr.onerror = (err) => {
                console.log(err);
              };
              xhr.send(fd);
            };
          }
          // const fd = new FormData(); // FormData 对象
          // fd.append("file", fileObj); // 文件对象 ImgByte // 是 input 中选中的文件列表
          // fd.append("token", res.data.credential); // 七牛云upload-token
          // const xhr = new XMLHttpRequest(); // XMLHttpRequest 对象
          // xhr.open("POST", "https://upload.qiniup.com", true); // post方式，uploadUrl为服务器请求地址，true 该参数规定请求是否异步处理。
          // xhr.onreadystatechange = () => {
          //   that.isUpload = false;
          //   if (xhr.readyState === 4) {
          //     if (xhr.status === 200) {
          //       const response = JSON.parse(xhr.responseText);
          //       console.log(response, xhr);
          //       const dataUrl = res.data.uriView + response.key;
          //       that.picUrlList.push(dataUrl);
          //       file.status = "done";
          //       file.message = "上传完成";
          //     } else {
          //       file.status = "failed";
          //       file.message = "上传失败";
          //     }
          //   }
          // };
          // xhr.onerror = () => {};
          // xhr.send(fd);
        });
    },
    // 展示解决方案
    show: function () {
      this.showProgramme = !this.showProgramme;
    },
    // 选择解决方案
    chooseProgramme: function (item) {
      this.showProgramme = false;
      this.userChooseProgramme = item.name;
    },
    // 提交
    submission: function () {
      const that = this;
      if (that.isUpload == false) {
        if (that.remark == "") {
          Toast("请填写问题与原因");
        } else {
          if (that.userChooseProgramme == "请选择") {
            Toast("请选择解决方案");
          } else {
            if (that.picList.length == 0) {
              Toast("请至少上传洗涤标签照片");
            } else {
              const chooseLabel = [];
              for (const i of that.labelList) {
                if (i.choose == true) {
                  chooseLabel.push(i.lableName);
                }
              }
              if (chooseLabel.length == 0) {
                Toast("请选择您遇到的问题");
              } else {
                // 验证完成，可以提交
                if (that.memberId == "") {
                  that.loading = true;
                  let params;
                  if (that.source == "dajingxi") {
                    params = new FormData();
                    params.append("labelList", chooseLabel);
                    params.append("orderId", that.orderId);
                    params.append("picUrlList", that.picUrlList);
                    params.append("remark", that.remark);
                    params.append("solution", that.userChooseProgramme);
                    params.append("type", 2);
                  } else {
                    params = {
                      labelList: chooseLabel,
                      orderId: that.orderId,
                      picUrlList: that.picUrlList,
                      remark: that.remark,
                      solution: that.userChooseProgramme,
                      type: 2,
                    };
                  }
                  that.http
                    .post(
                      // `https://${that.brand}.dajingxiapp.com/apiv2/ticket/add.json`,
                      // `https://meituan.dajingxiapp.cn/api/ticket/add`,
                      that.requestUrl,
                      params
                    )
                    .then((res) => {
                      that.loading = false;
                      console.log(res);
                      if (
                        (that.source == "dajingxi" &&
                          res.data.stateVO.code == 200) ||
                        (that.source !== "dajingxi" && res.status == 200)
                      ) {
                        Toast("提交成功");
                        that.$router.push({
                          path: "result",
                          query: { tel: that.userTel },
                        });
                      } else {
                        Toast(res.data.stateVO.msg);
                      }
                    })
                    .catch((err) => {
                      window.console.log(err);
                      that.loading = false;
                    });
                } else {
                  that.loading = true;
                  // };
                  const params = new URLSearchParams();
                  params.append("labelList", chooseLabel);
                  params.append("orderId", that.orderId);
                  params.append("picUrlList", that.picUrlList);
                  params.append("remark", that.remark);
                  params.append("solution", that.userChooseProgramme);
                  params.append("type", 2);
                  params.append("memberId", that.memberId);
                  params.append("openId", that.openId);
                  params.append("token", that.token);
                  that.http
                    .post(
                      `https://${that.brand}.dajingxiapp.com/apiv2/ticket/add.json`,
                      params
                    )
                    .then((res) => {
                      that.loading = false;
                      if (res.data.stateVO.code == 200) {
                        Toast("提交成功");
                        that.$router.push({
                          path: "result",
                          query: { tel: that.userTel },
                        });
                      } else {
                        Toast(res.data.stateVO.msg);
                      }
                    })
                    .catch((err) => {
                      window.console.log(err);
                      that.loading = false;
                    });
                }
              }
            }
          }
        }
      } else {
        Toast("图片正在上传中");
      }
    },
  },
  components: {
    [Uploader.name]: Uploader,
    [Loading.name]: Loading,
    [Toast.name]: Toast,
    [Checkbox.name]: Checkbox,
    [ActionSheet.name]: ActionSheet,
  },
};
</script>

<style scoped>
.head {
  background-image: linear-gradient(to bottom, #a1e4ea 0, #f8f9fb 100%);
  flex-direction: column;
  padding: 0.8rem 0 1.8rem 0;
}

.postion {
  position: relative;
  top: -1.5rem;
}

.centerDiv {
  width: 88%;
}

.module {
  width: 88%;
}

.orderNo {
  color: rgba(32, 32, 32, 0.4);
  border-bottom: 0.01rem solid rgba(32, 32, 32, 0.06);
  padding-bottom: 0.15rem;
}

.orangeMoudle {
  background: rgba(255, 171, 124, 0.15);
  border-radius: 0.15rem;
  padding: 0.25rem 0;
  margin: 0 auto;
  margin-top: 0.15rem;
  line-height: 1.6em;
  font-size: 0.25rem;
}

::v-deep .upload1 .van-uploader__preview {
  display: none;
}

::v-deep .upload2 .van-uploader__upload {
  display: none;
}

.centerDiv2 {
  width: 93%;
  margin: 0 auto;
}
</style>
