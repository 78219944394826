<template>
  <div class="content"></div>
</template>

<script>
export default {
  data() {
    return {
      outTradeNo: "",
      baseUrl: "https://weixin.dajingxiapp.com/apiv2/",
      successbuy: false,
    };
  },
  mounted() {
    this.outTradeNo = this.$route.query.outTradeNo || "";
    this.confirm();
  },
  methods: {
    confirm() {
      const that = this;
      if (that.outTradeNo) {
        that.http
          .get(
            `${that.baseUrl}recharge/payQuery.json?outTradeNo=${that.outTradeNo}`
          )
          .then((res) => {
            if (res.data.code == 200) {
              that.successbuy = true;
              that.$dialog
                .alert({
                  title: "温馨提示",
                  message: `购买成功，请关注公众号 “大鲸洗” 下单使用`,
                  theme: "round-button",
                  showCancelButton: false,
                  showConfirmButton: false,
                  confirmButtonColor: "#76c4cf",
                  cancelButtonColor: "#dfe1e5",
                })
                .then(() => {})
                .catch(() => {});
            } else {
              that.$dialog
                .alert({
                  title: "已支付？",
                  message: `点击查询购买结果`,
                  theme: "round-button",
                  confirmButtonColor: "#76c4cf",
                  confirmButtonText: "查询支付结果",
                  showCancelButton: false,
                })
                .then(() => {
                  that.confirm();
                })
                .catch(() => {});
            }
          });
      } else {
        that.$dialog
          .alert({
            title: "温馨提示",
            message: `未查到订单`,
            theme: "round-button",
            confirmButtonColor: "#76c4cf",
            confirmButtonText: "查询",
            showCancelButton: false,
            showConfirmButton:false,
          })
          .then(() => {})
          .catch(() => {});
      }
    },
  },
};
</script>