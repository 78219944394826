import axios from "axios"
import {
    Toast
} from 'vant';
// import envConfig from './env'
// axios.defaults.baseURL = envConfig().baseUrl
// 封装请求
function get(url, param) {
    Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中",
    });
    let promise = new Promise(function (resolve, reject) {
        axios.get(url, {
            params: param,
            timeout: 20000
        }).then(
            (res) => {
                Toast.clear();
                resolve(res)
                if (res.data.stateVO.code !== 200) {
                    Toast.fail(res.data.stateVO.msg);
                }
            }
        ).catch(
            (err) => {
                Toast.clear();
                reject(err)
            }
        )
    })
    return promise
}

function post(url, param) {
    Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中",
    });
    let promise = new Promise(function (resolve, reject) {
        axios.post(url, param, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
            (res) => {
                Toast.clear();
                resolve(res)
                if (res.data.stateVO.code !== 200) {
                    Toast.fail(res.data.stateVO.msg);
                }
            }
        ).catch(
            (err) => {
                Toast.clear();
                reject(err)
            }
        )
    })
    return promise
}

export default {
    get,
    post
}