<template>
  <div class="content">
    <!-- 头部 -->
    <div class="head">
      <div class="centerDiv">
        <div
          style="
            color: #fffeff;
            font-size: 0.4rem;
            font-weight: bold;
            margin-top: 0.1rem;
          "
        >
          大鲸洗售后服务单
        </div>
        <div style="color: #fffeff; line-height: 1.5em" class="maTo30">
          遇到问题请勿担心，我们的售后专员会在提交售后48小时内与您联系，请您注意接听010开头的电话号码
        </div>
      </div>
    </div>
    <!-- 订单号展示 -->
    <div class="module">
      <div class="centerDiv">
        <div class="flex aliCen bt">
          <div>订单号</div>
          <div class="mainColor">{{ orderNo }}</div>
        </div>
        <div class="commonText" style="margin-bottom: 0; margin-top: 0.4rem">
          对您造成的不便，我们深表抱歉
        </div>
      </div>
    </div>

    <!-- 投诉对象 -->
    <div class="module">
      <div class="centerDiv">
        <div>您要投诉</div>
        <div class="flex centerDiv bt aliCen maTo30">
          <div
            v-for="(item, index) in target"
            :key="index"
            class="commonText pflex"
            @click="chooseTarget(index)"
          >
            <van-checkbox
              v-model="item.choose"
              checked-color="#ed9027"
            ></van-checkbox>
            <div style="margin-left: 0.2rem">{{ item.lableName }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="module cflex">
      <div class="centerDiv">
        <div>留言</div>
        <van-field
          class="maTo20"
          v-model="remark"
          rows="2"
          autosize
          type="textarea"
          maxlength="150"
          placeholder="请输入留言"
          show-word-limit
        />
      </div>
    </div>

    <!-- 图片上传 -->
    <div class="module">
      <div class="centerDiv">
        <div>上传您的图片</div>
        <div class="maTo20">
          <van-uploader
            :after-read="afterRead"
            v-model="picList"
            :before-delete="deleteimg"
            :max-count="10"
          />
        </div>
      </div>
    </div>

    <!-- 占位 -->
    <div style="width: 100%; height: 1.5rem"></div>

    <!-- 底部按钮 -->
    <div class="pflex" style="position: fixed; bottom: 0.4rem; width: 100%">
      <div class="centerDiv">
        <van-button
          type="primary"
          round
          block
          color="#ed9027"
          @click="submission"
          >提交投诉</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Uploader, Loading, Toast, Checkbox } from "vant";
import "vant/lib/uploader/style";
import "vant/lib/loading/style";
import "vant/lib/toast/style";
import "vant/lib/checkbox/style";
export default {
  name: "app",
  data() {
    return {
      orderId: "",
      orderNo: "",
      remark: "",
      picList: [],
      loading: false, // 是否加载状态
      picUrlList: [],
      target: [], // 投诉目标
      userChooseTarget: "",
      token: "",
      memberId: "",
      openId: "",
      userTel: "", // 用户手机号
      isUpload: false, // 是否正在上传图片
      brand: "weixin",
    };
  },
  created() {
    let that = this;
    that.orderId = that.$route.query.orderId;
    that.orderNo = that.$route.query.orderNo;
    that.userTel = that.$route.query.tel;
    that.brand = that.$route.query.brand || "weixin";
    if (that.$route.query.token) {
      that.token = that.$route.query.token;
    }
    if (that.$route.query.memberId) {
      that.memberId = that.$route.query.memberId;
    }
    if (that.$route.query.openId) {
      that.openId = that.$route.query.openId;
    }
    that.loading = true;
    that.http
      .get(
        `https://${that.brand}.dajingxiapp.com/apiv2/ticket/labelList.json`,
        {
          ticketType: 6,
        }
      )
      .then((res) => {
        that.loading = false;
        that.target = res.data;
        for (let i of that.target) {
          i.choose = false;
        }
      })
      .catch(() => {
        that.loading = false;
      });
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == "/") {
      to.meta.keepAlive = true;
    } else {
      to.meta.keepAlive = false;
    }
    next();
  },
  methods: {
    // 照片删除回调
    deleteimg(e, name) {
      window.console.log(e);
      this.picUrlList.splice(name.index, 1);
      return true;
    },
    // 选择投诉对象
    chooseTarget: function (index) {
      let that = this;
      for (let i in that.target) {
        if (i == index) {
          that.target[i].choose = true;
        } else {
          that.target[i].choose = false;
        }
        this.$forceUpdate();
      }
    },
    afterRead(file) {
      const that = this;
      that.isUpload = true;
      file.status = "uploading";
      file.message = "上传中...";
      const fileObj = file.file; // 当前的图片
      that.http
        .get(
          `https://${that.brand}.dajingxiapp.com/apiv2/upload/token/ticket?token=56890a69-8658-4217-a1c6-6a27dba9f2f1`
        )
        .then((res) => {
          const fd = new FormData(); // FormData 对象
          fd.append("file", fileObj); // 文件对象 ImgByte // 是 input 中选中的文件列表
          fd.append("token", res.data.credential); // 七牛云upload-token
          const xhr = new XMLHttpRequest(); // XMLHttpRequest 对象
          xhr.open("POST", "https://upload.qiniup.com", true); // post方式，uploadUrl为服务器请求地址，true 该参数规定请求是否异步处理。
          xhr.onreadystatechange = () => {
            that.isUpload = false;
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                const dataUrl = res.data.uriView + response.key;
                that.picUrlList.push(dataUrl);
                file.status = "done";
                file.message = "上传完成";
              } else {
                file.status = "failed";
                file.message = "上传失败";
              }
            }
          };
          xhr.onerror = () => {};
          xhr.send(fd);
        });
    },
    // 提交
    submission: function () {
      let that = this;
      if (that.isUpload == false) {
        if (that.remark == "") {
          Toast("请填写问题与原因");
        } else {
          let userchooseTarget = [];
          for (let i of that.target) {
            if (i.choose == true) {
              userchooseTarget.push(i.lableName);
            }
          }
          if (userchooseTarget.length == 0) {
            Toast("请选择您要投诉的对象");
          } else {
            that.loading = true;
            let params = new URLSearchParams();
            params.append("labelList", userchooseTarget);
            params.append("orderId", that.orderId);
            params.append("remark", that.remark);
            params.append("picUrlList", that.picUrlList);
            params.append("type", 6);
            that.http
              .post(
                `https://${that.brand}.dajingxiapp.com/apiv2/ticket/add.json`,
                params
              )
              .then((res) => {
                that.loading = false;
                if (res.data.stateVO.code == 200) {
                  Toast("提交成功");
                  that.$router.push({
                    path: "/afterSale/result",
                    query: { tel: that.userTel },
                  });
                } else {
                  Toast(res.data.stateVO.msg);
                }
              })
              .catch(() => {
                that.loading = false;
              });
          }
        }
      } else {
        Toast("图片正在上传中");
      }
    },
  },
  components: {
    [Uploader.name]: Uploader,
    [Loading.name]: Loading,
    [Toast.name]: Toast,
    [Checkbox.name]: Checkbox,
  },
};
</script>

<style scoped>
.head {
  background-color: #85d4db;
  flex-direction: column;
  padding: 0.8rem 0;
}

.centerDiv2 {
    width: 93%;
    margin: 0 auto;
}
</style>